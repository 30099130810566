<template>
  <v-app class="desktopLayoutClass" >
    <div>
      <v-row justify="center">
        <v-dialog v-model="TestPopUp" persistent max-width="310">
          <v-card>
            <v-card-title style="word-break: normal">
             {{$t("Customer.UserProfile.Popup_Message")}}
            </v-card-title>
            <div class="mainBtnClass">
              <div>
                <v-btn  class="btnClass" outlined color="white" width="110px" @click="TestPopUp=false">
                 {{ $t("Customer.Home.Cancel") }}
                </v-btn>
              </div>
              <div>
                <v-btn color="primary" width="110px" @click="redirectToVideoCallTestPage">
                  {{ $t("Customer.Home.Verify_Now") }}
                </v-btn>
              </div>
            </div>
          </v-card>
        </v-dialog>
      </v-row>
    </div>
    <div
      v-show="show_header"
      class="header"
      style="
        display: flex;
        flex-direction: row;
        padding-top: 10px;
        padding-left: 5px;
      "
    >
      <div style="display: flex; flex-direction: row; width: 100%">
        <div
            style="width: 10%; padding-top: 6px"
            align="left"
            v-on:click="redirectToProfilePage()"
          >
            <img
              src="https://img.icons8.com/material-outlined/20/000000/left.png"
            />
          </div>
        <div style="width: 90%" align="left">
          <span style="font-weight: 600; font-size: 15pt">{{ $t("Customer.MyDevices.List_Of_Devices") }}</span>
        </div>
      </div>
    </div>
    <div style="background-color: #f5faff; height: 90vh; overflow-y: scroll">
      <v-card v-for="configDetail in configDetails" :key="configDetail._id" style="margin:16px; ">
        <div v-if="configDetail.verified_status" style="background:#6FCF97; width:40%; border-radius: 6px 0px;">
           <p style="color:white; padding:5px; font-weight:600 ">   <img style="margin-right:4px; width:15px"
                          src="https://s3iconimages.mymedicine.com.mm/tickMark.svg"
                        />{{ $t("Customer.MyDevices.Verified") }}</p>
        </div>
          <div v-else style="background:#EB5757; width:40%; border-radius: 6px 0px;">
           <p style="color:white; padding:5px; font-weight:600 ">   <img style="margin-right:4px; width:15px" src="https://s3iconimages.mymedicine.com.mm/crossMark.svg"/>{{ $t("Customer.MyDevices.Not_Verified") }}</p>
        </div>
        <div class="cardContent">
          <div class="dataClass">
            <div >
              <p class="cardtitle">{{ $t("Customer.MyDevices.Device") }}:</p>
            </div>
            <div>
             <p class="cardDetails"> {{configDetail.device}} </p>
            </div>
          </div>
           <div class="dataClass">
            <div >
              <p class="cardtitle">{{ $t("Customer.MyDevices.Browser") }}:</p>
            </div>
            <div>
             <p class="cardDetails"> {{configDetail.browser}} </p>
            </div>
          </div>
          <div class="dataClass">
            <div >
              <p class="cardtitle">{{ $t("Customer.MyDevices.Last_Login") }}:</p>
            </div>
            <div>
             <p  style="margin-bottom: 0px !important" class="cardDetails">
                {{configDetail.dateToRendder}}</p>
               <!-- <DateRendered class="dateValueClass" :dateVal="configDetail.dateToRendder" > </DateRendered> -->
              <span class="cardDetails">  {{configDetail.timeToRendder}}</span>
            </div>
          </div>
          <div style="padding: 8px">
          <v-btn width="90%" v-if="configDetail.currentDevice" style="color:white" color="#EB5757" @click="TestPopUp=true"> {{ $t("Customer.MyDevices.Verify_Now") }} </v-btn>
          </div>
        </div>
      </v-card>
    </div>
  </v-app>
</template>
<script>
import axios from "axios";
import DateRendered from './dateRender.vue';
import TimeRender from './TimeRender.vue';
export default {

    name:'ListOfdevice',
    data(){
      return{
        userId:'',
        configDetails:'',
        currentCustomer:'',
        customer_name:'',
        customer_id:'',
        TestPopUp: false,
        show_header: true,

      };
    },
     components: {
     DateRendered,
      TimeRender
    },
    mounted(){
      var userAgent = navigator.userAgent
      if(userAgent.includes("kbzpay")){
        this.show_header = false;
      }
      if(this.$store.state.locale == "en")
        document.title = "My Devices"
      else
        document.title = "မိမိ Device များ"
      this.userId = this.$route.params.userId;
      this.$i18n.locale = this.$store.state.locale;
      this.customer_name = this.$store.state.name;
      this.currentCustomer = this.$cookies.get("customerToken");
      var access = this.$cookies.get("access");
        var readCustomerListOfDevice = {
        token: this.currentCustomer,
        typeOfUser: "CUSTOMER"
      };
      axios
        .post(
          process.env.VUE_APP_BACKEND_URL + "/listOfDevicePage",
          readCustomerListOfDevice
        )
        .then((listOfDevicePageResponse) => {
          this.configDetails = listOfDevicePageResponse.data.user_config_data
          this.configDetails.forEach(x => {
            // x.dateToRendder = x.lastLogin.split("T")[0]
            // x.timeToRendder = x.lastLogin.split("T")[1].split(".")[0]
            x.dateToRendder = new Date(x.lastLogin).toLocaleDateString();
            x.timeToRendder = new Date(x.lastLogin).toLocaleTimeString();
          });
        })
        .catch((listOfDevicePageError) => {
          console.log(listOfDevicePageError);
        });
     },
    methods:{
      redirectToVideoCallTestPage(){
         this.$router.push({
        name: "VideoCallTestPage",
      });
      },
      redirectToProfilePage(){
         this.$router.go(-1);
      }
    }
  }
</script>

<style scoped>
.tableClass {
  width: 80%;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
}
.mainCardClass {
  display: flex;
  flex-direction: column;
  margin-top: 5%;
}
.cardDeviceAndBrowser {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.contentClass {
  display: flex;
  flex-direction: row;
  padding: 10px;
}
.VerificationAndBtn {
  display: flex;
  flex-direction: row;
   justify-content: space-between;
}
.Verification{
    display: flex;
    flex-direction: row;
}
.cardContent{
  display: flex;
  flex-direction: column;
}
.dataClass{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0px 10px;
}
.cardtitle{
  color: #828282 !important;
  font-weight: normal;
  font-size: 16px;
  padding: 0px 5px;
}
.cardDetails{
  color: #333333 !important;
  font-weight: 500 !important;
  font-size: 16px;
  padding: 0px 5px;
}
.mainBtnClass{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 10px;
}
.btnClass{
  background-color: grey;
}
</style>